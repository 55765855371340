import React, { useState } from "react";
import { Link } from "react-router-dom";
import palette from "../../utils/palette";
import logo from "../../ressources/images/logo/logo2.png";
import { IoIosBackspace, IoIosMenu } from "react-icons/io";

export default function Sidebar(props) {
  const [menuExtended, setMenuExtended] = useState(false);

  const toggleMenu = () => {
    setMenuExtended(!menuExtended);
  };

  if (window.innerWidth < 800) {
    //MOBILE VERSION
    return (
      <div>
        {menuExtended === true ? (
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              backgroundColor: palette.blueBackground,
              flex: 1,
              zIndex: 1,
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          >
            <div>
              <Link
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
                to="/"
              >
                <img
                  src={logo}
                  style={{ width: "50%", marginTop: "2%" }}
                  alt="the mathis tondenier logo"
                />
              </Link>
                <IoIosBackspace style={{position: 'absolute', top: '2%', left: '2%'}} color="#ffffff" size={50} onClick={toggleMenu}/> 
            </div>
            <ul
              style={{
                listStyleType: "none",
                paddingLeft: 0,
              }}
            >
              <MobileNavButton title="A propos" linkTo="/about" toggleMenu={toggleMenu}/>
              <MobileNavButton title="Compétences" linkTo="/skills" toggleMenu={toggleMenu} />
              <MobileNavButton title="Travail" linkTo="/work" toggleMenu={toggleMenu} />
              <MobileNavButton title="Contact" linkTo="/contact" toggleMenu={toggleMenu} />
            </ul>
          </div>
        ) : (
          <button onClick={toggleMenu} style={{borderWidth: 0, backgroundColor: '#FFFFFF', position: 'absolute', top: '1%', left: '1%'}}><IoIosMenu size={50}/></button>
        )}
      </div>
    );
  }
  return (
    // DESKTOP VERSION
    <div
      style={{
        backgroundColor: palette.blueBackground,
        flexDirection: "column",
        width: "19.5vw",
        height: "100vh",
      }}
    >
      <Link
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
        to="/"
      >
        <img
          src={logo}
          style={{ width: "70%", marginTop: "5%" }}
          alt="the mathis tondenier logo"
        />
      </Link>
      <Link to="/" style={{ textDecoration: "none" }}>
        <h1
          style={{
            color: "#FFFFFF",
            textAlign: "center",
            //fontFamilly: "Montserrat",
            fontWeight: "bold",
            fontSize: "1.8vw",
            marginBottom: 0,
          }}
        >
          Mathis Tondenier
        </h1>
        <p
          style={{
            marginTop: "0.6vw",
            color: "#FFFFFF",
            textAlign: "center",
            //fontFamilly: "Montserrat",
            fontSize: "1.2vw",
          }}
        >
          Web/Mobile Developper
        </p>
      </Link>
      <div
        style={{
          marginTop: "50%",
          flexDirection: "colummn",
        }}
      >
        <ul
          style={{
            listStyleType: "none",
            paddingLeft: 0,
          }}
        >
          <NavButton title="A propos" linkTo="/about" />
          <NavButton title="Compétences" linkTo="/skills" />
          <NavButton title="Travail" linkTo="/work" />
          <NavButton title="Contact" linkTo="/contact" />
        </ul>
      </div>
    </div>
  );
}

const buttonStyle = {
  hover: {
    color: "#2a9d8f",
    //fontFamilly: "Montserrat",
    fontWeight: 600,
    fontSize: "2.05vw",
    textDecoration: "none",
  },
  nonHover: {
    color: "#FFFFFF",
    textAlign: "center",
    //fontFamilly: "Montserrat",
    fontWeight: 600,
    fontSize: "2vw",
    marginBottom: 0,
    textDecoration: "none",
  },
};

const MobileNavButton = (props) => {
  return (
    <li
      style={{
        marginTop: '5vh',
        marginBottom: "3vh",
        textAlign: "center",
        listStyleType: "none",
        textDecorationStyle: "none",
      }}
    >
      <Link
        to={props.linkTo}
        style={{
          color: "#FFFFFF",
          textAlign: "center",
          //fontFamilly: "Montserrat",
          fontWeight: 600,
          fontSize: "5.5vh",
          marginBottom: 0,
          textDecoration: "none",
        }}
        onClick={props.toggleMenu}
      >
        {props.title}
      </Link>
    </li>
  );
};

const NavButton = (props) => {
  const [hovered, setHovered] = useState(false);
  return (
    <li
      style={{
        marginBottom: "2vw",
        textAlign: "center",
        listStyleType: "none",
        textDecorationStyle: "none",
      }}
    >
      <Link
        to={props.linkTo}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={hovered === true ? buttonStyle.hover : buttonStyle.nonHover}
      >
        {props.title}
      </Link>
    </li>
  );
};
