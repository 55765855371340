import React, { useState } from "react";
import Lottie from "lottie-react";
import contactAnimation from "../../ressources/animations/contact.json";
import checkAnimation from "../../ressources/animations/check.json";
import loadingAnimation from "../../ressources/animations/loading.json";
import emailjs from "@emailjs/browser";

export default function Contact(props) {
  const [buttonColor, setButtonColor] = useState("#2a9d8f");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [object, setObject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [animationVisible, setAnimationVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendMessage = (e) => {
    e.preventDefault();
    if (name.length > 3) {
      if (email.length > 5 && email.includes("@")) {
        if (message.length > 30) {
          setLoading(true);
          emailjs
            .send(
              `service_gjpbo3v`,
              "template_57rn0vf",
              { name, email, object, message },
              "sXeA57a8fFI0oiEfl"
            )
            .then((result) => {
              messageSent();
            });
          messageSent();
        } else {
          showError("Message non valide");
        }
      } else {
        showError("Email non valide");
      }
    } else {
      showError("Nom invalide");
    }
  };

  const messageSent = () => {
    setName("");
    setEmail("");
    setObject("");
    setMessage("");
    setAnimationVisible(true);
    setTimeout(() => {
      setLoading(false);
      setAnimationVisible(false);
    }, 2000);
  };

  const showError = (e) => {
    setError(e);
    setErrorVisible(true);
    setTimeout(() => {
      setErrorVisible(false);
    }, 1500);
  };

  if (window.innerWidth < 800) {
    return (
      //VERSION MOBILE
      <div
        style={{
          flexDirection: "column",
          flex: 1,
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 1.3,
            display: "flex",
            paddingTop: "20%",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "4vh",
                  fontWeight: 800,
                  marginBottom: "5%",
                }}
              >
                Me contacter
              </h2>
              <p
                style={{
                  fontSize: "2.5vh",
                  textAlign: "center",
                  fontWeight: 600,
                  marginBottom: "4%",
                  marginLeft: "2%",
                  marginRight: "2%",
                }}
              >
                Je suis intéressé par tout types de missions.
                <br />
                <br />
                Si vous avez la moindre question ou requête n’hésitez pas a me
                contact en utilisant ce formulaire ou en m’envoyant un mail a
                l’adresse suivante : {"  "}
                <a
                  href="mailto:contact@mathistdn.com"
                  style={{ textDecoration: "none", color: "#2a9d8f" }}
                >
                  contact@mathistdn.com
                </a>
              </p>
            </div>
            {loading === false ? (
              <div
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
              >
                <input
                  type="text"
                  style={{
                    borderWidth: 0,
                    backgroundColor: "#e9e9e9",
                    height: "6vh",
                    fontSize: "2vh",
                    outline: "none",
                    marginRight: "5%",
                    marginLeft: "5%",
                    marginBottom: "5%",
                    fontWeight: 600,
                    color: "#757575",
                    paddingLeft: "5%",
                    borderRadius: 5,
                  }}
                  placeholder="Nom"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
                <input
                  type="text"
                  style={{
                    borderWidth: 0,
                    backgroundColor: "#e9e9e9",
                    height: "6vh",
                    fontSize: "2vh",
                    outline: "none",
                    marginRight: "5%",
                    marginLeft: "5%",
                    marginBottom: "5%",
                    fontWeight: 600,
                    color: "#757575",
                    paddingLeft: "5%",
                    borderRadius: 5,
                  }}
                  placeholder="Email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <input
                  type="text"
                  style={{
                    borderWidth: 0,
                    backgroundColor: "#e9e9e9",
                    height: "6vh",
                    fontSize: "2vh",
                    outline: "none",
                    marginRight: "5%",
                    marginLeft: "5%",
                    marginBottom: "5%",
                    fontWeight: 600,
                    color: "#757575",
                    paddingLeft: "5%",
                    borderRadius: 5,
                  }}
                  placeholder="Objet"
                  value={object}
                  onChange={(event) => setObject(event.target.value)}
                />
                <textarea
                  type="text"
                  style={{
                    borderWidth: 0,
                    backgroundColor: "#e9e9e9",
                    height: "35vh",
                    fontSize: "2vh",
                    fontWeight: 600,
                    outline: "none",
                    fontFamily: "Montserrat",
                    color: "#757575",
                    paddingLeft: "1.5%",
                    marginBottom: "2%",
                    marginRight: "5%",
                    marginLeft: "5%",
                    resize: "none",
                    borderRadius: 5,
                  }}
                  placeholder="Message"
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                />
                {errorVisible === true && (
                  <h2 style={{ color: "red", textAlign: "center" }}>{error}</h2>
                )}
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: buttonColor,
                      borderRadius: 15,
                      paddingRight: "5%",
                      paddingLeft: "5%",
                      paddingTop: "0.5%",
                      paddingBottom: "0.5%",
                      height: "5vh",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      marginBottom: "1%",
                      outline: "none",
                      borderWidth: 0,
                      MozUserFocus: "select-all",
                    }}
                    onMouseEnter={() => setButtonColor("#2eafa0")}
                    onMouseLeave={() => setButtonColor("#2a9d8f")}
                    onClick={sendMessage}
                  >
                    <h2
                      style={{
                        fontWeight: 700,
                        textAlign: "center",
                        color: "#FFFFFF",
                      }}
                    >
                      Envoyer
                    </h2>
                  </button>
                </div>
              </div>
            ) : animationVisible ? (
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <Lottie
                  animationData={checkAnimation}
                  loop={false}
                  style={{ height: "30vh" }}
                />
              </div>
            ) : (
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <Lottie
                  animationData={loadingAnimation}
                  loop
                  style={{ height: "25vh" }}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    // VERSION DESKTOP
    <div
      style={{
        flexDirection: "row",
        flex: 1,
        display: "flex",
      }}
    >
      <div
        style={{
          flex: 1.3,
          display: "flex",
          paddingTop: "6%",
        }}
      >
        <div
          style={{
            flex: 1,
            marginLeft: "10%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <h2
              style={{
                textAlign: "left",
                fontSize: "2.5vw",
                fontWeight: 800,
                marginBottom: "5%",
              }}
            >
              Me contacter
            </h2>
            <p
              style={{
                fontSize: "1.2vw",
                textAlign: "left",
                fontWeight: 600,
                marginBottom: "4%",
              }}
            >
              Je suis intéressé par tout types de missions.
              <br />
              <br />
              Si vous avez la moindre question ou requête n’hésitez pas a me
              contact en utilisant ce formulaire ou en m’envoyant un mail a
              l’adresse suivante : {"  "}
              <a
                href="mailto:contact@mathistdn.com"
                style={{ textDecoration: "none", color: "#2a9d8f" }}
              >
                contact@mathistdn.com
              </a>
            </p>
          </div>
          {loading === false ? (
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginBottom: "3%",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    paddingRight: "3%",
                  }}
                >
                  <input
                    type="text"
                    style={{
                      borderWidth: 0,
                      backgroundColor: "#e9e9e9",
                      height: "6vh",
                      fontSize: "1.3vw",
                      outline: "none",
                      marginRight: "5%",
                      fontWeight: 600,
                      color: "#757575",
                      width: "100%",
                      paddingLeft: "5%",
                      borderRadius: 5,
                    }}
                    placeholder="Nom"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
                <div style={{ flex: 1.4, display: "flex" }}>
                  <input
                    type="text"
                    style={{
                      borderWidth: 0,
                      backgroundColor: "#e9e9e9",

                      fontWeight: 600,
                      height: "6vh",
                      display: "flex",
                      flex: 1,
                      fontSize: "1.3vw",
                      outline: "none",
                      color: "#757575",
                      borderRadius: 5,
                      paddingLeft: "5%",
                    }}
                    placeholder="Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
              </div>
              <input
                type="text"
                style={{
                  borderWidth: 0,
                  backgroundColor: "#e9e9e9",
                  height: "6vh",
                  fontSize: "1.3vw",

                  fontWeight: 600,
                  outline: "none",
                  color: "#757575",
                  paddingLeft: "1%",
                  marginBottom: "3%",
                  borderRadius: 5,
                }}
                placeholder="Objet"
                value={object}
                onChange={(event) => setObject(event.target.value)}
              />
              <textarea
                type="text"
                style={{
                  borderWidth: 0,
                  backgroundColor: "#e9e9e9",
                  height: "6vh",
                  fontSize: "1.3vw",
                  fontWeight: 600,
                  outline: "none",
                  fontFamily: "Montserrat",
                  color: "#757575",
                  paddingLeft: "1.5%",
                  marginBottom: "2%",
                  flex: 1,
                  resize: "none",
                  borderRadius: 5,
                }}
                placeholder="Message"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
              {errorVisible === true && (
                <h2 style={{ color: "red", textAlign: "center" }}>{error}</h2>
              )}
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <button
                  style={{
                    backgroundColor: buttonColor,
                    borderRadius: 15,
                    paddingRight: "5%",
                    paddingLeft: "5%",
                    paddingTop: "0.5%",
                    paddingBottom: "0.5%",
                    height: "5vh",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    marginBottom: "1%",
                    outline: "none",
                    borderWidth: 0,
                    MozUserFocus: "select-all",
                  }}
                  onMouseEnter={() => setButtonColor("#2eafa0")}
                  onMouseLeave={() => setButtonColor("#2a9d8f")}
                  onClick={sendMessage}
                >
                  <h2
                    style={{
                      fontWeight: 700,
                      textAlign: "center",
                      color: "#FFFFFF",
                    }}
                  >
                    Envoyer
                  </h2>
                </button>
              </div>
            </div>
          ) : animationVisible ? (
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <Lottie
                animationData={checkAnimation}
                loop={false}
                style={{ height: "30vh" }}
              />
            </div>
          ) : (
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <Lottie
                animationData={loadingAnimation}
                loop
                style={{ height: "25vh" }}
              />
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          ></div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          animationData={contactAnimation}
          loop={true}
          style={{ height: "55vh" }}
        />
      </div>
      <a href="https://www.instagram.com/mathis.tondenier/">instagram</a>
    </div>
  );
}
