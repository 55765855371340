import React, { useState } from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import Dev from "../../ressources/animations/design.json";

export default function About(props) {
  const [buttonColor, setButtonColor] = useState("#2a9d8f");
  if (window.innerWidth < 800) {
    // VERSION MOBILE
    return (
      <div style={{ flexDirection: "column", flex: 1, display: "flex" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            paddingTop: "15%",
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <h2
              style={{
                textAlign: "center",
                fontSize: "3vh",
                fontWeight: 900,
                marginBottom: "5%",
              }}
            >
              Qui suis-je ?
            </h2>
            <p
              style={{
                fontSize: "2vh",
                textAlign: "center",
                fontWeight: 700,
                marginBottom: "10%",
              }}
            >
              Je suis un développeur front-end et back-end français passioné par
              le design, le code, la création de sites web et d’applications
              mobiles.
              <br />
              <br />
              Je suis très organisé, indépendant, j’adore résoudre des problèmes
              et je suis très attentif au détails.
              <br />
              <br />
              Je suis intéressé par tout types de projet : Applications mobiles
              IOS ou Android Création d’API et de serveurs Création de sites
              webs.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                style={{
                  backgroundColor: buttonColor,
                  borderRadius: 10,
                  paddingTop: "1%",
                  paddingBottom: "1%",
                  paddingRight: "5%",
                  paddingLeft: "5%",
                  textDecoration: "none",
                }}
                to="/contact"
                onMouseEnter={() => setButtonColor("#34c3b1")}
                onMouseLeave={() => setButtonColor("#2a9d8f")}
              >
                <h1
                  style={{
                    color: "#FFFFFF",
                    fontSize: "6vw",
                    marginBottom: "1%",
                    marginTop: "1%",

                    fontWeight: 700,
                  }}
                >
                  Me contacter
                </h1>
              </Link>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "12%",
          }}
        >
          <Lottie animationData={Dev} loop={true} style={{ height: "40vh" }} />
        </div>
      </div>
    );
  }
  return (
    //VERSION DESKTOP
    <div style={{ flexDirection: "row", flex: 1, display: "flex" }}>
      <div
        style={{
          flex: 1,
          display: "flex",
          paddingTop: "10%",
        }}
      >
        <div
          style={{
            flex: 1,
            marginLeft: "10%",
          }}
        >
          <h2
            style={{
              textAlign: "left",

              fontSize: "2.5vw",
              fontWeight: 900,
              marginBottom: "5%",
            }}
          >
            Qui suis-je ?
          </h2>
          <p
            style={{
              fontSize: "1.2vw",
              textAlign: "left",
              fontWeight: 700,
              marginBottom: "10%",
            }}
          >
            Je suis un développeur front-end et back-end français passioné par
            le design, le code, la création de sites web et d’applications
            mobiles.
            <br />
            <br />
            Je suis très organisé, indépendant, j’adore résoudre des problèmes
            et je suis très attentif au détails.
            <br />
            <br />
            Je suis intéressé par tout types de projet : Applications mobiles
            IOS ou Android Création d’API et de serveurs Création de sites webs.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Link
              style={{
                backgroundColor: buttonColor,
                borderRadius: 10,
                paddingTop: "1%",
                paddingBottom: "1%",
                paddingRight: "5%",
                paddingLeft: "5%",
                textDecoration: "none",
              }}
              to="/contact"
              onMouseEnter={() => setButtonColor("#34c3b1")}
              onMouseLeave={() => setButtonColor("#2a9d8f")}
            >
              <h1
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.5vw",
                  marginBottom: "1%",
                  marginTop: "1%",

                  fontWeight: 700,
                }}
              >
                Me contacter
              </h1>
            </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "12%",
        }}
      >
        <Lottie animationData={Dev} loop={true} style={{ height: "70vh" }} />
      </div>
    </div>
  );
}
