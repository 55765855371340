import { useState } from "react";

export default function Gladys(props) {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <h1
          style={{
            textAlign: "center",
            
            fontWeight: 700,
            marginTop: "0.5%",
            marginBottom: 0,
            fontSize: "1.9vw",
          }}
        >
          Gladys
        </h1>
        <h3
          style={{
            marginTop: "0.5%",
            textAlign: "center",
            fontFamily: "Montserrat",
            fontSize: "1.2vw",
          }}
        >
          Réalisation de 3 plugins pour le projet open source Gladys assistant:
          <br /> 90 % de backend et 10% de front end
        </h3>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../../../ressources/images/work/gladys.jpg")}
            style={{ height: "55vh", width: "auto" }}
            alt="sworkout mockup"
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Bouton
          title="Site du projet"
          index={1}
          link="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiw7LjXzMH5AhVJ-4UKHbvdAaEQFnoECAYQAQ&url=https%3A%2F%2Fgladysassistant.com%2Ffr%2F&usg=AOvVaw1MImEUg7VfBlWF7jvEHWFx"
        />
        <Bouton
          title="Mon travail"
          index={2}
          link="https://github.com/mTondenier?tab=repositories&q=gladys&type=&language=&sort="
        />
      </div>
    </div>
  );
}

const Bouton = (props) => {
  const [buttonColor, setButtonColor] = useState("#2a9d8f");
  return (
    <a
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        backgroundColor: buttonColor,
        borderBottomLeftRadius: props.index === 1 ? 15 : 0,
        borderBottomRightRadius: props.index === 2 ? 15 : 0,
        marginRight: props.index === 1 ? 1.5 : 0,
        marginLeft: props.index === 2 ? 1.5 : 0,
        textDecoration: "none",
      }}
      href={props.link}
      target="_blank"
      rel="noreferrer"
      onMouseEnter={() => setButtonColor("#2eafa0")}
      onMouseLeave={() => setButtonColor("#2a9d8f")}
    >
      <h3
        style={{
          color: "#FFFFFF",
          
          fontWeight: 600,
          fontSize: 20,
          marginTop: "3%",
          marginBottom: "3%",
        }}
      >
        {props.title}
      </h3>
    </a>
  );
};
