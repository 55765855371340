import { useState } from "react";

export default function EFT(props) {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <h1
          style={{
            textAlign: "center",

            fontWeight: 700,
            marginTop: "0.5%",
            marginBottom: 0,
            fontSize: "1.9vw",
          }}
        >
          {"EFT & Taping"}
        </h1>
        <h3
          style={{
            marginTop: "0.5%",
            textAlign: "center",
            fontFamily: "Montserrat",
            fontSize: "1.2vw",
          }}
        >
          Réalisation d'une applicaiton mobile ansi que d'un site vitrine
        </h3>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../../../ressources/images/work/EFT.jpg")}
            style={{ height: "50vh", width: "auto" }}
            alt="sworkout mockup"
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Bouton
          title="Site du projet"
          index={1}
          link="https://eft.framer.website"
        />
      </div>
    </div>
  );
}

const Bouton = (props) => {
  const [buttonColor, setButtonColor] = useState("#2a9d8f");
  return (
    <a
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        backgroundColor: buttonColor,
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        textDecoration: "none",
      }}
      href={props.link}
      target="_blank"
      rel="noreferrer"
      onMouseEnter={() => setButtonColor("#2eafa0")}
      onMouseLeave={() => setButtonColor("#2a9d8f")}
    >
      <h3
        style={{
          color: "#FFFFFF",

          fontWeight: 600,
          fontSize: 20,
          marginTop: "3%",
          marginBottom: "3%",
        }}
      >
        {props.title}
      </h3>
    </a>
  );
};
