import React, { useEffect, useState } from "react";
import Gladys from "./Routes/gladys";
import Sworkout from "./Routes/sworkout";
import { animated, useSpring } from "react-spring";
import GTG from "./Routes/gtg";
import EFT from "./Routes/eft";
import SkillsDB from "./Routes/skillsdb";
import Furthearth from "./Routes/furthearth";

export default function ModalRouter(props) {
  const style = useSpring({
    to: { scaleX: 1, scaleY: 1 },
    from: { scaleX: 0.05, scaleY: 0.05 },
    config: { bounce: true },
  });
  return (
    <animated.div
      style={{
        display: "flex",
        flex: 1,
        opacity: 1,
        marginRight: "12%",
        marginLeft: "12%",
        marginBottom: "4%",
        marginTop: "4%",
        ...style,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          backgroundColor: "#FFFFFF",
          borderRadius: 15,
        }}
      >
        <Routes route={props.route} />
      </div>
    </animated.div>
  );
}

const Routes = (props) => {
  switch (props.route) {
    case "sworkout":
      return <Sworkout />;
    case "gladys":
      return <Gladys />;
    case "gtg":
      return <GTG />;
    case "eft":
      return <EFT />;
    case "skillsDB":
      return <SkillsDB />;
    case "furthearth":
      return <Furthearth />;
    default:
      return (
        <div
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <h1 style={{ textAlign: "center" }}>Loading</h1>
        </div>
      );
  }
};
