import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import ModalRouter from "./modalRouter";

export default function Work(props) {
  const [modalOpened, setModalState] = useState(false);
  const [itemSelected, setItemSlected] = useState("none");

  const toggleModal = (item) => {
    setItemSlected(item);
    setModalState(!modalOpened);
  };

  const handleClickCapture = (event) => {
    console.log(event);
    if (event.target.className === "modalBackground") {
      toggleModal();
    }
  };

  if (window.innerWidth < 800) {
    return (
      // VERSION MOBILE
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {modalOpened && (
          <div
            className="modalBackground"
            style={{
              zIndex: 10,
              position: "fixed",
              display: "flex",
              flex: 1,
              width: "100%",
              height: "100%",
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            }}
            onClickCapture={handleClickCapture}
          >
            <ModalRouter route={itemSelected} />
          </div>
        )}

        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            marginTop: "25%",
          }}
        >
          <div
            style={{ display: "flex", flex: 1, cursor: "pointer" }}
            onClick={() => window.open("https://sworkout.fr", "_blank")}
          >
            <Item
              resize="cover"
              src={require("../../ressources/images/Items/Sworkout.png")}
              fadeTime={2}
            />
          </div>
          <div
            style={{ display: "flex", flex: 1, cursor: "pointer" }}
            onClick={() =>
              window.open("https://gladysassistant.com/fr/", "_blank")
            }
          >
            <Item
              resize="cover"
              src={require("../../ressources/images/Items/gladys.png")}
              fadeTime={4}
            />
          </div>

          <div style={{ display: "flex", flex: 1 }}></div>
        </div>
      </div>
    );
  }

  return (
    // VERSION DESKTOP
    <div
      style={{
        flex: 1,
        display: "flex",
      }}
    >
      {modalOpened && (
        <div
          className="modalBackground"
          style={{
            zIndex: 10,
            position: "fixed",
            display: "flex",
            flex: 1,
            width: "100%",
            height: "100%",
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
          onClickCapture={handleClickCapture}
        >
          <ModalRouter route={itemSelected} />
        </div>
      )}

      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            flex: 1,
            display: "flex",
          }}
        >
          <div
            style={{ display: "flex", flex: 1, cursor: "pointer" }}
            onClick={() => toggleModal("sworkout")}
          >
            <Item
              resize="cover"
              src={require("../../ressources/images/Items/Sworkout.png")}
              fadeTime={2}
            />
          </div>

          <div
            style={{ display: "flex", flex: 1, cursor: "pointer" }}
            onClick={() => toggleModal("gladys")}
          >
            <Item
              resize="cover"
              src={require("../../ressources/images/Items/gladys.png")}
              fadeTime={4}
            />
          </div>
          <div
            style={{ display: "flex", flex: 1, cursor: "pointer" }}
            onClick={() => toggleModal("eft")}
          >
            <Item
              resize="contain"
              src={require("../../ressources/images/Items/EFT.png")}
              fadeTime={4}
            />
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            flex: 1,
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              borderWidth: 0,
              borderStyle: "none",
              cursor: "pointer",
            }}
            onClick={() => toggleModal("gtg")}
          >
            <Item
              resize="cover"
              src={require("../../ressources/images/Items/gtg.png")}
              fadeTime={4}
            />
          </div>
          <div
            style={{ display: "flex", flex: 1, cursor: "pointer" }}
            onClick={() => toggleModal("skillsDB")}
          >
            <Item
              resize="cover"
              src={require("../../ressources/images/Items/skillsDB.png")}
              fadeTime={4}
            />
          </div>
          <div
            style={{ display: "flex", flex: 1, cursor: "pointer" }}
            onClick={() => toggleModal("furthearth")}
          >
            <Item
              resize="cover"
              src={require("../../ressources/images/Items/furthearth.png")}
              fadeTime={4}
            />
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            flex: 1,
            display: "flex",
          }}
        >
          <div style={{ display: "flex", flex: 1 }}></div>
          <div style={{ display: "flex", flex: 1 }}></div>
          <div style={{ display: "flex", flex: 1 }}></div>
        </div>
        <div
          style={{
            flexDirection: "row",
            flex: 1,
            display: "flex",
          }}
        >
          <div style={{ display: "flex", flex: 1 }}></div>
          <div style={{ display: "flex", flex: 1 }}></div>
          <div style={{ display: "flex", flex: 1 }}></div>
        </div>
      </div>
    </div>
  );
}

const Item = (props) => {
  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    setOpacity(1);
  });
  if (props.resize === "cover") {
    return (
      <CoverImg
        src={props.src}
        alt="project logo"
        style={{ opacity, transition: `${props.fadeTime}s ease-in` }}
      />
    );
  } else if (props.resize === "contain") {
    return (
      <ContainIlmg
        src={props.src}
        alt="project logo"
        style={{ opacity, transition: `${props.fadeTime}s ease-in` }}
      />
    );
  }
};

const CoverImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContainIlmg = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;
