import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default function ProgressBar(props) {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(props.percentage);
  });
  return (
    <div style={{ marginBottom: "3%" }}>
      <h2
        style={{
          fontSize: "1.1vw",
          
          fontWeight: 700,
          marginBottom: "2%",
        }}
      >
        {props.title}
      </h2>
      <div
        style={{
          backgroundColor: "#eeeeee",
          width: "25vw",
          height: "1.8vh",
        }}
      >
        <div
          style={{
            width: `${25 * (progress / 100)}vw`,
            height: "1.8vh",
            backgroundColor: "#2a9d8f",
            transition: "3s ease",
          }}
          className="progress"
        ></div>
      </div>
    </div>
  );
}

export function MobileProgressBar(props) {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(props.percentage);
  });
  return (
    <div style={{ marginBottom: "3%" }}>
      <h2
        style={{
          fontSize: "2.5vh",
          textAlign: 'center',
          fontWeight: 700,
          marginBottom: "4%",
        }}
      >
        {props.title}
      </h2>
      <div
        style={{
          backgroundColor: "#eeeeee",
          width: "45vh",
          height: "1.8vh",
        }}
      >
        <div
          style={{
            width: `${45 * (progress / 100)}vh`,
            height: "1.8vh",
            backgroundColor: "#2a9d8f",
            transition: "3s ease",
          }}
          className="progress"
        ></div>
      </div>
    </div>
  );
}
