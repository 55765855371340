import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Sidebar from "./components/Sidebar";
import Contact from "./Views/Contact";
import About from "./Views/About";
import Work from "./Views/Work";
import Skills from "./Views/Skills";

export default function App(props) {

  return (
    <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
      <BrowserRouter>
        <Sidebar />
        <Routes>
          <Route path="/" element={<About />} index />
          <Route path="/about" element={<About />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/work" element={<Work />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
