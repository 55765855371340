import React from "react";
import { Link } from "react-router-dom";
import ProgressBar, { MobileProgressBar } from "../../components/ProgressBar";

export default function Skills(props) {
  if (window.innerWidth < 800) {
    return (
      //VERSION MOBILE
      <div
      style={{
        flexDirection: "column",
        flex: 1,
        display: "flex",
        backgroundColor: "#FFFFFF",
        paddingTop: '10%',
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          paddingTop: "10%",
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <h2
            style={{
              textAlign: "center",
              
              fontSize: "4vh",
              fontWeight: 900,
              marginBottom: "5%",
            }}
          >
            Mes compétences
          </h2>
          <p
            style={{
              fontSize: "2vh",
              textAlign: "center",
              fontWeight: 700,
              marginBottom: "10%",
              marginLeft: '2%',
              marginRight: '2%'

            }}
          >
            J’ai commencé ma carrière de développeur il y 5 ans. J’ai
            principalement travaillé dans des projets d’entrepreneuriat et des
            projets open source.
            <br />
            <br />
            Je suis capable de développer pour vous des interfaces claires et
            épurées ainsi que des programmes complets, fonctionnels et simples
            d’utilisation.
            <br />
            <br />
            Mon expertise est orientée vers les petits et moyens projets. J’ai
            aussi des compétences solides avec le CMS Wordpress.
            <br />
            <br />
            <Link
              to="/contact"
              style={{ textDecoration: "none", color: "#2a9d8f" }}
            >
              Contactez
            </Link>{" "}
            moi pour plus de détails
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Link
              style={{
                borderRadius: 10,
                paddingTop: "1%",
                paddingBottom: "1%",
                paddingRight: "5%",
                paddingLeft: "5%",
                textDecoration: "none",
              }}
              to="/contact"
            >
              <h1
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.5vw",
                  marginBottom: "1%",
                  marginTop: "1%",
                  
                  fontWeight: 700,
                }}
              >
                Me contacter
              </h1>
            </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "12%",
        }}
      >
        <div style={{ flexDirection: "column", display: "flex" }}>
          <MobileProgressBar title="Mobile App ( React Native )" percentage={100} />
          <MobileProgressBar title="Sites Wordpress" percentage={98} />
          <MobileProgressBar title="Backend (Node JS)" percentage={85} />
          <MobileProgressBar title="Frontend (React JS)" percentage={80} />
        </div>
      </div>
    </div>
    )
  }
  return (
    // VERSION DESKTOP
    <div
      style={{
        flexDirection: "row",
        flex: 1,
        display: "flex",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          paddingTop: "10%",
        }}
      >
        <div
          style={{
            flex: 1,
            marginLeft: "10%",
          }}
        >
          <h2
            style={{
              textAlign: "left",
              
              fontSize: "2.5vw",
              fontWeight: 900,
              marginBottom: "5%",
            }}
          >
            Mes compétences
          </h2>
          <p
            style={{
              fontSize: "1.2vw",
              textAlign: "left",
              
              fontWeight: 700,
              marginBottom: "10%",
            }}
          >
            J’ai commencé ma carrière de développeur il y 5 ans. J’ai
            principalement travaillé dans des projets d’entrepreneuriat et des
            projets open source.
            <br />
            <br />
            Je suis capable de développer pour vous des interfaces claires et
            épurées ainsi que des programmes complets, fonctionnels et simples
            d’utilisation.
            <br />
            <br />
            Mon expertise est orientée vers les petits et moyens projets. J’ai
            aussi des compétences solides avec le CMS Wordpress.
            <br />
            <br />
            <Link
              to="/contact"
              style={{ textDecoration: "none", color: "#2a9d8f" }}
            >
              Contactez
            </Link>{" "}
            moi pour plus de détails
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Link
              style={{
                borderRadius: 10,
                paddingTop: "1%",
                paddingBottom: "1%",
                paddingRight: "5%",
                paddingLeft: "5%",
                textDecoration: "none",
              }}
              to="/contact"
            >
              <h1
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.5vw",
                  marginBottom: "1%",
                  marginTop: "1%",
                  
                  fontWeight: 700,
                }}
              >
                Me contacter
              </h1>
            </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "12%",
        }}
      >
        <div style={{ flexDirection: "column", display: "flex" }}>
          <ProgressBar title="Mobile App ( React Native )" percentage={100} />
          <ProgressBar title="Sites Wordpress" percentage={98} />
          <ProgressBar title="Backend (Node JS)" percentage={85} />
          <ProgressBar title="Frontend (React JS)" percentage={80} />
        </div>
      </div>
    </div>
  );
}
